(function () {
  'use strict';

  class ModuleCreateCtrl {
    constructor(Modules, ModuleSet, Resources, $mdToast) {
      let vm = this;
      vm.ctrlName = 'ModuleCtrl';
      vm.Modules = Modules;
      vm.ModuleSet = ModuleSet;
      vm.Resources = Resources;
      vm.$mdToast = $mdToast;
      vm.pattern = /^[0-9]*$/;
      vm.init();
    }
    init() {
      const vm = this;
      vm.isLoading = false;
      vm.module = {
        module_categories: []
      };
      vm.selectedSet = {};
      if (vm.$form) {
        vm.$form.$setPristine();
        vm.$form.$setUntouched();
      }
      vm.moduleSetIndex();
    }
    moduleSetIndex() {
      const vm = this;
      vm.ModuleSet.moduleSetIndex()
      .then((data) => {
        vm.ModuleSetsPayload = data;
      });
    }
    submitModule(form) {
      const vm = this;
      if (form.$valid) {
        vm.$form = form;
        vm.setUpModule();
      }
    }
    setUpModule() {
      const vm = this;
      angular.forEach(vm.selectedSet.module_categories, function (_, k, obj) {
        const category = {
          slug: obj[k].slug,
          value: obj[k].value,
          name: obj[k].name
        };
        vm.module.module_categories.push(category);
      });
      vm.module.module_set_id = vm.selectedSet.id;
      vm.createModule();
    }
    createModule() {
      let vm = this;
      vm.isLoading = true;
      vm.Modules.modulesCreate(vm.module)
        .then(()=> {
          vm.init();
          vm.message();
        })
        .catch((response) => {
          const {data: {
            error
          }} = response;
          angular.forEach(error, function (v) {
            vm.message(v.join(' '));
          });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    }
    message(msg = 'Module Created Successfully') {
      const vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name learning.module.controller:ModuleCtrl
   *
   * @description
   *
   */
  angular
    .module('learning.module')
    .controller('ModuleCreateCtrl', ModuleCreateCtrl);
}());
